.veedree .tableHeaders {
}

.veedree tr {
  display: table-row;
}

.veedree th {
  border-bottom: 1px solid #eee;
  border-top-width: 0px;
  height: 53px;
  background-color: white;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: -0.2px;
  padding-left: 16px;
}

.veedree tr,
.veedree td {
  padding: 10px 4px 10px 16px;
  text-align: left;
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: white;
  border-bottom: 1px solid #eee;
  border-top-width: 0px;
  font-size: 13px;
}

.headers {
}

.veedree table {
  width: 100%;
  max-width: 100%;
  min-width: 1200px;
}

.veedree .tableRow td:last-child {
  text-align: center;
  background-color: white;
  width: 5%;
  padding: 0px;
}

.veedree th:last-child {
  text-align: center;
  padding: 0px;
}

.veedree .tableRow td:first-child {
  padding-left: 24px;
  width: 4%;
  border-right-width: 0px;
}

.veedree th:first-child {
  border-right-width: 0px;
}

.veedree th:nth-child(2) {
  border-left-width: 0px;
}

.veedree .tableRow td:nth-child(2) {
  border-left-width: 0px;
}

.veedree tr:hover td {
  background-color: #e8f5ff !important;
}

.table-row-totals td {
  font-weight: bold
}