body {
  cursor: default;
  font-family: BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 1.42857142857143;
  font-style: normal;
  font-weight: 400;
  color: #2b2b2b;
  margin: 0px;
  padding: 0px;
}

.home h1 {
  font-family: 'avenir' !important;
}

p {
  margin-bottom: 0px !important;
}

.modal p {
  font-weight: 600;
  color: #828282;
  font-size: 12px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.bor {
  border: 1px solid #eee;
}

.flex {
  display: flex;
}

.rowColumn {
  display: flex;
  justify-content: column;
  align-items: flex-start;
}

.rowFlex {
  display: flex;
  flex-direction: row;
}

.rowCenterFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fCol {
  display: flex;
  flex-direction: column;
}

.fRow {
  display: flex;
  flex-direction: row;
}

.fRowM {
  display: flex;
  flex-direction: row;
}

.jCen {
  justify-content: center;
}

.jEnd {
  justify-content: flex-end;
}

.aCen {
  align-items: center;
}

.jSta {
  justify-content: flex-start;
}

.betweenFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.centerFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jBetween {
  justify-content: space-between;
}

.columnCenterFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.centerTopFlex {
  display: flex;
  justify-content: space-between;
  align-items: top;
}

.startFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.endFlex {
  display: flex;
  justify-content: flex-end;
}

.centerCenterFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexBetweenColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.containerNav {
  position: fixed;
  height: 100vh;
  background-color: #f5f8fa;
  padding-left: 24px;
  padding-right: 24px;
}

#header {
  padding: 0px 24px 0px 32px;
  position: relative;
  z-index: 500;
  height: 60px;
  width: calc(100% - 80px);
  position: fixed;
  margin-left: 80px;
  background-color: white;
  border-bottom: 1px solid #eeeeee75;
}

.avatar {
  margin-bottom: 8px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 3px solid white;
  background-color: #e6f7ff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0px 10px 0 rgba(34, 43, 55, 0.1);
}

.avatar p {
  color: #0496ff;
  font-weight: 600;
  font-size: 14px;
}

.ant-select-selector {
  height: 34px !important;
}

.hover {
  cursor: pointer;
}

.sidebar {
  z-index: 510;
  background-color: #0496ff;
  height: 100vh;
  position: fixed;
  padding: 0px 0px 36px 0px;
  width: 80px;
}

.topDiv {
  margin-top: 60px;
  padding-top: 4px;
  background-color: white;
}

.menuToggler {
  position: absolute;
  top: 12px;
}

.leftie {
  left: 244px;
  transition: left 0.6s;
}

.rightie {
  left: 64px;
  transition: left 0.6s;
}

.navFull {
  transition: width 0.6s;
  width: 240px;
}

.navSmall {
  width: 80px;
  transition: width 0.6s;
}

.sidebarLink {
  padding: 12px;
}

.containerContent {
  margin-left: 80px;
  background-color: white;
  width: calc(100% - 80px);
}

.logo {
  width: 88px;
  margin-right: 24px;
  margin-left: 32px;
}

.home {
  height: 100vh;
}

.nav {
  margin-top: 12px;
}

.nav a {
  color: #828282;
}

.nav a:hover {
  color: #0496ff;
}

.nav img {
  margin: 20px;
}

.ant-dropdown {
  z-index: 20000;
}

h1 {
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -1px;
}

.home .ant-btn {
  font-weight: 900 !important;
  background-color: #ffc48c !important;
  border: 0px !important;
  color: white !important;
  font-size: 16px !important;
  height: 40px !important;
  font-family: 'avenir' !important;
}

.home .ant-btn:hover {
  background-color: #f6b271 !important;
}

.ant-btn.orange {
  font-weight: 900 !important;
  background-color: #ffc48c !important;
  border: 0px !important;
  color: white !important;
  font-family: 'avenir' !important;
}

.ant-btn.blue {
  font-weight: 900 !important;
  background-color: #0496ff !important;
  border: 0px !important;
  color: white !important;
  font-family: 'avenir' !important;
  border-radius: 24px !important;
  padding-left: 24px;
  padding-right: 24px;
}

.marginRight {
  margin-right: 8px;
}

.ant-btn.orange:hover {
  background-color: #f6b271 !important;
}

.ant-btn.blue:hover {
  background-color: #0c89e3 !important;
}

.spaceL {
  margin-left: 4px;
}

.headerTable td {
  font-size: 10px;
  font-weight: 600;
  color: #828282;
  background-color: #fff;
  padding: 12px 0px;
}

thead,
.tableHeader {
  display: table-row;
}

.tableAround {
  padding-top: 4px;
  padding-left: 4px;
  margin-top: 8px;
  margin-left: 12px;
  border-radius: 8px;
}

.blueCircle {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #fff;
  margin: 1px;
}

.w25 {
  width: 25%;
}

.w20 {
  width: 20%;
}

.w12 {
  width: 12.5%;
}

.w20 {
  width: 20%;
}

.w7 {
  width: 5%;
}

.w10 {
  width: 10%;
}

.hTable {
  background-color: white;
  padding: 16px 8px;
}

table {
  width: 98%;
  margin-left: 12px;
}

table tr {
  width: 100%;
  border-radius: 4px;
}

table tr:hover {
  background-color: #e6f7ff;
}

tr:nth-child(even) {
}

.w25 {
  background-color: red;
}

.envTable td {
  width: 8%;
  padding: 12px 0px;
  font-size: 13px;
}

.activeBox {
  background-color: #0496ff25;
}

.checkBox {
  border-radius: 4px;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border: 1px solid #bbb;
  display: flex;
}

.navHeader {
  padding: 16px 24px;
  background-color: white;
  width: 100%;
  border-bottom: 1px solid #eee;
}

.spacing {
}

.spacing:last-child {
  display: none;
}

.modal .flex {
}

.ant-modal {
  width: 100vh !important;
  top: 60px !important;
}

.modal .ant-input,
.modal .ant-select {
  margin-bottom: 16px;
  background-color: #f5f8fa;
  border-color: #eee !important;
}

.modal .ant-select-selection {
  background-color: #f5f8fa;
  border-color: #eee !important;
}

.modal .ant-select {
  width: 100%;
}

.modal input {
  border-radius: 4px;
  border: 1px solid #eee;
  background-color: #f5f8fa;
  width: 100%;
  outline: none;
  height: 32px;
  padding: 4px 12px;
  font-size: 14px;
  -webkit-appearance: none;
  margin-bottom: 16px;
}

.ant-modal-body {
  padding-top: 16px !important;
}

.ant-modal-close-x {
  padding-top: 4px;
}

.full {
  width: 100%;
}

.fullW {
  width: 50%;
}

.smallNav {
  width: 100%;
  height: 80px;
  background-color: white;
  padding-left: 24px;
  padding-right: 24px;
}

.newButton {
  position: absolute;
  bottom: 0px;
  padding: 24px;
}

.shadow {
  box-shadow: 0 20px 50px 0 rgba(34, 43, 55, 0.2);
}

.smallMenuNav {
  position: fixed;
  width: 100vw;
  height: calc(100vh - 170px);
  left: 0px;
  top: 60px;
  background-color: white;
  border-top: 1px solid #eee;
  padding-left: 24px;
  padding-right: 24px;
  overflow-y: scroll;
  overflow: scroll;
  z-index: 999999;
}

.smallMenuNav p {
  font-weight: 600;
  color: #2b2b2b !important;
}

.smallLinkNav {
  width: 100%;
  padding: 24px 0px;
  border-bottom: 1px solid #eee !important;
}

.smallLinkNav a:hover,
.smallLinkNav a:hover {
  border-color: #eee !important;
  color: #828282 !important;
}

.smallLinkNav .arrow {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  border-top: 3px solid #000;
  border-right: 3px solid #000;
  transform: rotate(45deg);
}

.closeNav {
  margin-top: 8px;
  background-color: #f5f8fa;
  border-radius: 4px;
  width: 40px;
  height: 40px;
}

.navLogo {
  width: 64px;
}

.navImg {
  width: 28px;
}

.linkImg {
  height: 40px;
  width: 40px;
  margin: 8px;
}

#menuToggle {
  z-index: 1;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

#menuToggle input {
  display: block;
  width: 33px;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
  /* hide this */
  z-index: 2;
  /* and place it over the hamburger */
}

.active {
  background-color: #004a7f;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.headerText p {
  font-size: 16px;
  font-weight: 900;
}

#menuToggle span {
  display: block;
  width: 22px;
  height: 2px;
  margin-bottom: 3px;
  position: relative;
  background: #0496ff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px -3px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  width: 19px;
  transform: rotate(45deg) translate(-2px, -9px);
  background: #0496ff;
}

/*
 * But let's hide the middle one.
 */

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */

#menuToggle input:checked ~ span:nth-last-child(2) {
  opacity: 1;
  width: 19px;
  transform: rotate(-45deg) translate(-1px, 7px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */

/* #menu {} */

#menu li {
  padding: 6px 0;
  font-size: 14px;
  color: #2b2b2b;
}

/*
 * And let's fade it in from the left
 */

#menuToggle input:checked ~ ul {
  transform: scale(1, 1);
  opacity: 1;
  margin-top: 20px;
}

.tableHeader td:first-child {
  padding-left: 8px;
}

.recordsForm {
  display: flex;
  margin: 12px;
  border-radius: 4px;
  padding: 12px 12px;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #eeeeee75;
}

@media screen and (max-width: 768px) {
  .hideMob {
    display: none;
  }

  .recordsForm {
    padding: 0px;
    display: block;
    margin: 12px;
    border-radius: 4px;
    background-color: #eeeeee75;
  }

  .fRowM {
    display: flex;
    flex-direction: column;
  }

  .containerContent {
    width: 100% !important;
    margin-left: 0%;
    background-color: white;
  }

  .betweenFlex {
    display: block;
  }

  .fullW {
    width: 100%;
  }

  .headerTable td {
    font-size: 14px;
  }

  td {
    font-size: 12px;
  }

  .topDiv {
    margin-top: 0px;
  }

  .tableAround {
    margin-left: 0px;
    padding: 0px;
  }

  table {
    margin-left: 0px;
    width: 100%;
    margin: 0px 8px;
  }

  .hTable {
    padding: 12px 8px;
    background-color: white;
  }

  .ant-btn.blue {
    border-radius: 2px !important;
    margin-right: 0px !important;
  }

  .userInfo {
    margin-top: 0px !important;
    padding: 0px 12px !important;
  }

  .searchQueryBox {
    padding: 0px 12px 12px 12px !important;
  }

  .deletedRecords {
    margin-left: 8px;
  }

  .deletedRecordsHeader.ant-page-header.deletedRecordsHeader.ant-page-header-ghost {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.userInfo table {
  margin-left: 0px;
}

.searchQueryBox {
  padding-top: 0px !important;
}

.searchQueryBox table {
  margin-left: 0px;
}

.deletedRecordsHeader.ant-page-header.deletedRecordsHeader.ant-page-header-ghost {
  padding-left: 12px;
}

@media screen and (min-width: 768px) {
  .smallNav,
  .hideLarge {
    display: none;
  }
}

.noLeftPad {
  padding-left: 0px;
}

.actionBTN img {
  align-self: center;
  padding: 0px;
  width: 24px;
}

.tooltip-icon {
  width: 16px;
  height: 16px;
}
