table.eitje-table {
  overflow-x: auto;
  white-space: nowrap;
  background: #ffffff;
  border-collapse: collapse;
  width: calc(100vw - calc(var(--table-offset-left) * 1px));
}
table.eitje-table.eitje-table-no-content {
  background: unset;
  position: fixed;
}
table.eitje-table.eitje-table-no-content .empty-list {
  width: calc(100vw - calc(var(--table-offset-left) * 1px));
}
table.eitje-table-individual-rows {
  margin-left: calc(var(--table-offset-left) * 1px);
}
table.eitje-table-individual-rows .eitje-virtuoso-wrapper {
  padding-top: calc(calc(var(--row-height) * 1px) + calc(var(--row-gutter-vertical) * 1px));
}
table.eitje-table-individual-rows div[data-test-id='virtuoso-item-list'] {
  padding-bottom: calc(calc(var(--row-height) * 1px) + 2px + 4 * calc(var(--row-gutter-vertical) * 1px)) !important;
}
table.eitje-table-grouped-rows .eitje-virtuoso-wrapper {
  padding-bottom: calc(2px + 2 * calc(var(--row-gutter-vertical) * 1px));
  margin-top: -6px;
}
table.eitje-table-individual-rows .eitje-virtuoso-wrapper {
  margin-top: 4px;
}
table.eitje-table-grouped-rows .eitje-virtuoso-wrapper {
  margin-top: -6px;
}
table.eitje-table th.eitje-table-header-cell:last-child .eitje-table-header-cell-content {
  width: calc(var(--column-width) * 1px);
}
