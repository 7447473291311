.grouped-list-select .grouped-list-select-label-container {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom-color: #eeeeee;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 12px 8px;
  cursor: pointer;
  grid-gap: 8px;
  gap: 8px;
}
.grouped-list-select .grouped-list-select-label-container * {
  cursor: pointer;
}
.grouped-list-select .grouped-list-select-label-container .grouped-list-select-label-count {
  font-weight: 600;
  width: 12px;
  height: 12px;
  min-width: 12px;
}
.grouped-list-select .grouped-list-select-label-container .grouped-list-select-label-icon {
  width: 12px;
  height: 12px;
  min-width: 12px;
}
.grouped-list-select .grouped-list-select-label-container .grouped-list-select-label {
  flex: 1 1;
  font-weight: 600;
}
.grouped-list-select .grouped-list-select-label-container .grouped-list-collapser-icon {
  width: 12px;
  height: 12px;
  min-width: 12px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.grouped-list-select :is(.flat-list-select-item) {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom-color: #eeeeee;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 12px 8px;
  cursor: pointer;
  grid-gap: 8px;
  gap: 8px;
}
.grouped-list-select :is(.flat-list-select-item) * {
  cursor: pointer;
}
.grouped-list-select :is(.flat-list-select-item):is(.selected) {
  background-color: #e8f5ff;
}
.grouped-list-select :is(.flat-list-select-item) :is(.flat-list-select-item-text) {
  color: #2b2b2b;
  font-size: 14px;
  letter-spacing: -0.2px;
  margin-bottom: 0px;
  color: #828282;
  font-size: 12px;
  font-weight: 600;
  flex: 1 1;
}
.grouped-list-select :is(.flat-list-select-item) :is(.flat-list-select-item-text).dangerous {
  color: #ff0020;
}
.grouped-list-select :is(.flat-list-select-item) :is(.flat-list-select-item-text).eitje-blue {
  color: #0496ff;
}
.grouped-list-select-group.expanded :is(.grouped-list-collapser-icon) {
  transform: rotate(-180deg);
}
.grouped-list-select-group.selected-within :is(.grouped-list-select-label-container) {
  background-color: #e8f5ff;
}
