table.eitje-table thead.eitje-table-header-wrapper {
  display: inline;
}
table.eitje-table thead.eitje-table-header-wrapper tr.eitje-table-header th.eitje-table-header-cell {
  width: calc(var(--column-width) * 1px);
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  background-color: #f6f6f6;
}
table.eitje-table thead.eitje-table-header-wrapper tr.eitje-table-header th.eitje-table-header-cell:first-child {
  z-index: 11;
}
table.eitje-table .eitje-table-header-cell-content {
  border-bottom-color: #eeeeee;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin: 0 0 0 8px;
  height: calc(var(--header-height) * 1px);
  width: 100%;
  min-width: calc(var(--column-width) * 1px);
}
table.eitje-table .eitje-table-header-cell-content .eitje-table-header-cell-title-container {
  display: inline-flex;
  margin: 12px 0 0 0;
}
table.eitje-table .eitje-table-header-cell-content .eitje-table-header-cell-title-container .eitje-table-header-cell-title-text {
  font-weight: 600;
  color: #2b2b2b;
  display: inline-block;
  font-size: 12px;
}
table.eitje-table .eitje-table-header-cell-content .eitje-table-header-cell-title-container .eitje-table-header-cell-title-text .tooltip-icon {
  margin: 0 0 0 4px;
}
table.eitje-table .eitje-table-header-cell-content .eitje-table-header-cell-title-container .eitje-table-header-cell-icon {
  height: 14px;
  width: 14px;
  margin: 2px 0 0 2px;
  display: inline-block;
}
table.eitje-table.eitje-table-grouped-rows .eitje-table-header-cell-title-container {
  margin: calc(12px - 1px) 0 0 0;
}
table.eitje-table .eitje-table-header-cell-content h5 {
  font-size: 10px;
}
table.eitje-table.eitje-table-header-sticky.eitje-table-individual-rows th.eitje-table-header-cell {
  top: calc(var(--table-offset-top) * 1px);
}
table.eitje-table.eitje-table-header-sticky.eitje-table-individual-rows div[data-test-id='virtuoso-item-list'] div[data-index] {
  position: -webkit-sticky;
  position: sticky;
  top: calc(var(--table-offset-top) * 1px);
}
table.eitje-table.eitje-table-header-sticky.eitje-table-grouped-rows th.eitje-table-header-cell {
  top: 0;
}
table.eitje-table.eitje-table-first-column-sticky.eitje-table-individual-rows th.eitje-table-header-cell {
  left: calc(var(--table-offset-left) * 1px);
}
table.eitje-table.eitje-table-first-column-sticky.eitje-table-grouped-rows th.eitje-table-header-cell {
  left: 0;
}
table.eitje-table th.eitje-table-header-cell {
  min-width: calc(calc(100vw - calc(var(--table-offset-left) * 1px) - calc(var(--first-column-width) * 1px) - (2 * calc(var(--row-gutter-horizontal) * 1px))) / var(--column-count));
}
table.eitje-table th.eitje-table-header-cell:first-child {
  min-width: calc(var(--first-column-width) * 1px);
}
