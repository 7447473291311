table.eitje-table td.eitje-table-body-cell {
  border-top-color: #eeeeee;
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-color: #eeeeee;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  height: calc(var(--row-height) * 1px);
  display: inline-flex;
  flex: 1 1;
  justify-content: flex-start;
  align-items: center;
  margin: calc(var(--row-gutter-vertical) * 1px) 0 0 0;
  padding: 0 0 0 8px;
  color: #828282;
  font-size: 12px;
}
table.eitje-table td.eitje-table-body-cell .eitje-table-body-cell-content {
  display: inline-flex;
}
table.eitje-table td.eitje-table-body-cell .eitje-table-body-cell-content .tooltip-icon {
  margin: 2px 0 0 4px;
}
table.eitje-table td.eitje-table-body-cell:first-child {
  min-width: calc(var(--first-column-width) * 1px);
  max-width: calc(var(--first-column-width) * 1px);
}
