tbody.eitje-table-row-wrapper {
  display: flex;
  flex-grow: 1;
}
tbody.eitje-table-row-wrapper tr.eitje-table-row {
  cursor: pointer;
  display: flex;
  flex-grow: 1;
}
tbody.eitje-table-row-wrapper tr.eitje-table-row * {
  cursor: pointer;
}
tbody.eitje-table-row-wrapper tr.eitje-table-row:hover td {
  background: #e8f5ff;
}
table.eitje-table td.eitje-table-body-cell:first-child {
  border-left-color: #eeeeee;
  border-left-width: 1px;
  border-left-style: solid;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-left: calc(var(--row-gutter-horizontal) * 1px);
}
table.eitje-table td.eitje-table-body-cell:last-child {
  border-right-color: #eeeeee;
  border-right-width: 1px;
  border-right-style: solid;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-right: calc(var(--row-gutter-horizontal) * 1px);
}
table.eitje-table.eitje-table-first-column-sticky td.eitje-table-body-cell {
  position: -webkit-sticky;
  position: sticky;
}
table.eitje-table.eitje-table-first-column-sticky td.eitje-table-body-cell:first-child {
  z-index: 9;
}
table.eitje-table.eitje-table-first-column-sticky.eitje-table-individual-rows td.eitje-table-body-cell {
  left: calc(calc(var(--row-gutter-horizontal) * 1px) + calc(var(--table-offset-left) * 1px));
}
table.eitje-table.eitje-table-first-column-sticky.eitje-table-grouped-rows td.eitje-table-body-cell {
  left: calc(var(--row-gutter-horizontal) * 1px);
}
table.eitje-table.eitje-table-last-column-sticky td.eitje-table-body-cell,
table.eitje-table.eitje-table-last-column-sticky th.eitje-table-header-cell {
  position: -webkit-sticky;
  position: sticky;
}
table.eitje-table.eitje-table-last-column-sticky td.eitje-table-body-cell {
  right: calc(var(--row-gutter-horizontal) * 1px);
}
table.eitje-table.eitje-table-last-column-sticky th.eitje-table-header-cell {
  right: 0;
}
table.eitje-table td.eitje-table-body-cell:first-child .eitje-table-body-cell-content,
table.eitje-table th.eitje-table-header-cell:first-child .eitje-table-header-cell-content {
  width: calc(var(--first-column-width) * 1px);
}
a.eitje-table-row-link {
  display: flex;
}
table.eitje-table .eitje-table-row-expandable td.eitje-table-body-cell {
  background: #f5f8fa;
}
