.eitje-dropdown-list-container .flat-list-select {
  max-height: calc(320px - 60px);
  overflow-y: scroll;
}
.eitje-dropdown-list-container .clear-selection-button-container {
  border-top-color: #eeeeee;
  border-top-width: 1px;
  border-top-style: solid;
}
.eitje-dropdown-list-container .clear-selection-button-container .clear-selection-button {
  margin-top: 8px;
  margin-bottom: 8px;
}
.eitje-dropdown-list-container .grouped-list-select-label-count {
  margin-top: -7px;
}
