:is(.ant-modal-wrap) .eitje-dropdown-list-select-container {
  position: static;
  position: initial;
}
.eitje-dropdown-list-select-container {
  position: relative;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container {
  position: absolute;
  border-color: #eeeeee;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  border-top: 0 solid #eeeeee;
  overflow: auto;
  max-height: 320px;
  margin-top: 4px;
  width: inherit;
  z-index: 1000;
  box-shadow: 0px 5px 15px 0px #aaaaaa25;
  background-color: #ffffff;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container * {
  cursor: pointer;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container.dropdown-pop-left {
  left: -102%;
  bottom: 0px;
  border-top-color: #eeeeee;
  border-top-width: 1px;
  border-top-style: solid;
  box-shadow: 0px 4px 8px 4px #aaaaaa25;
  background-color: #ffffff;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container.max-height-unset {
  max-height: unset;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container.disabled-checkbox :is(.flat-list-select-item) :is(.flat-list-select-item-text) {
  color: #2b2b2b;
  font-size: 14px;
  letter-spacing: -0.2px;
  margin-bottom: 0px;
  color: #828282;
  padding-left: 35px;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container.disabled-checkbox :is(.flat-list-select-item) :is(.flat-list-select-item-text).dangerous {
  color: #ff0020;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container.disabled-checkbox :is(.flat-list-select-item) :is(.flat-list-select-item-text).eitje-blue {
  color: #0496ff;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container.disabled-checkbox :is(.flat-list-select-item):is(.selected) :is(.flat-list-select-item-text) {
  color: #2b2b2b;
  font-weight: 600;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container::-webkit-scrollbar {
  display: none;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container :is(.flat-list-select-item) {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom-color: #eeeeee;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 12px 8px;
  grid-gap: 8px;
  gap: 8px;
  padding: 16px 8px;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container :is(.flat-list-select-item):is(.selected) {
  background-color: #e8f5ff;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container :is(.flat-list-select-item) :is(.flat-list-select-item-text) {
  color: #2b2b2b;
  font-size: 14px;
  letter-spacing: -0.2px;
  margin-bottom: 0px;
  color: #828282;
  font-size: 12px;
  font-weight: 600;
  flex: 1 1;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container :is(.flat-list-select-item) :is(.flat-list-select-item-text).dangerous {
  color: #ff0020;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container :is(.flat-list-select-item) :is(.flat-list-select-item-text).eitje-blue {
  color: #0496ff;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container :is(.custom-list-item) {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom-color: #eeeeee;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 12px 8px;
  grid-gap: 8px;
  gap: 8px;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container :is(.custom-list-item) :is(.grouped-list-select-label-icon) {
  width: 12px;
  height: 12px;
  min-width: 12px;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container :is(.custom-list-item) :is(.grouped-list-select-label) {
  flex: 1 1;
  font-weight: 600;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container :is(.custom-list-item):is(.selected) {
  background-color: #e8f5ff;
}
.eitje-dropdown-list-select-container.error :is(button) {
  border: 1px dashed red;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container {
  border-top-color: #eeeeee;
  border-top-width: 1px;
  border-top-style: solid;
  border-radius: 4px;
  max-width: 100%;
  min-width: 200px;
}
.eitje-dropdown-list-select-container .eitje-dropdown-list-container .search-container {
  padding: 8px;
}
