

.logoLogin {
    width: 80px;
    padding-top: 24px;
}

.phones {
    width: 180px; 
    box-shadow: 0 0px 10px 0 rgba(34,43,55,.1);  
}

.rooster {

	margin-right: 12px;

}

.uren {
	

}

.loginField {
  width: 70%;
  margin: auto;
  padding-bottom: 80px;
}

.leftField {
  background-color: #fff;
  height: 100vh;
  width: 50%;
  padding-left: 32px;
  padding-right: 32px;
}

.rightField {
  background-color: #f5f8fa;
  width: 50%;
    -webkit-transition: right 2s ease-in; 
}


.welcomeText {	
	font-size: 32px;
	color: #2b2b2b;
	font-weight: 600;
	line-height: 36px;
}

.welcomeSubText {
	padding-bottom: 24px;	
	font-size: 16px;
	color: #828282;
	font-weight: 400;
	line-height: 20px;
}

.rightTextField {
	text-align: center;
	padding-top: 24px;
	width: 70%;
	margin-left: auto;
	margin-right: auto;
}

.signUpText {	
	font-size: 20px;
	color: #2b2b2b;
	font-weight: 600;
	line-height: 36px;	
}

.signUpSub {
	padding-bottom: 24px;	
	font-size: 14px;
	color: #828282;
	font-weight: 400;
	line-height: 20px;
}

.loginField .ant-input-affix-wrapper {
	padding: 0px !important;
	border: 0px !important;
	background-color: unset;
}



.loginField .ant-input {
	margin-bottom: 16px !important;
	border: 1px solid #eee !important;
	border-radius: 4px !important;
	width: 100% !important;
	height: 40px;
}

.ant-input-affix-wrapper>input.ant-input {
padding-left: 4px;
}

.loginField .ant-input::selection {
	border: 1px solid #eee !important;
	border-radius: 4px !important;
	padding-left: 8px !important;
}

.loginField .ant-input-affix-wrapper .ant-input-prefix, .loginField .ant-input-affix-wrapper .ant-input-suffix {
	top: 25% !important;
	right: 24px !important;
	position: absolute;
}








@media screen and (max-width: 768px) {

.leftField, .rightField, .loginField {
    width: 100%;
  }
}




.slider {
	position: relative;
  height: 100vh;
  width: 100vw;
  margin: 0px auto;
  overflow: hidden;
}

