.onderElkaar {
  display: flex;
  flex-direction: column;
}

.naastElkaar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
}

.billTable {
  display: flex;
  width: 400px;
  place-content: space-between;
  font-size: 1.2em;
}

.billInfoName {
  font-weight: 600;
}

.billInfoData {
}

.flexMeDown {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linkImg p {
  background-color: white;
}

.eitje-dropdown {
  min-width: 100px;
}
